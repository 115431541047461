@font-face {
    font-family: 'VolteRounded-Semibold';
    src: url('../font/VolteRounded-Semibold.otf')
}

/* These styles make the body full-height */
html,
body {
    height: 100%;
}

/* These styles disable body scrolling if you are using <ScrollView> */
body {
    overflow: hidden;
}

/* These styles make the root element full-height */
#root {
    display: flex;
    height: 100%;
}